<template>
  <div class="loading-page">
    <div class="content">
      <div class="uwu-loader">
        <div class="uwu-loader-icon"></div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.loading-page {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;

  //.content {
  //  margin-top: 30%;
  //}

  &::before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: #ffffffa2;
    backdrop-filter: blur(10px);
  }
}
</style>
