import { createInstance } from "@/lib/fn";
import Http from "@/lib/http";
class App extends Http {
    async getTranslations() {
        var _a;
        const { response } = await this.request("GET", "translations");
        return (_a = response.data) !== null && _a !== void 0 ? _a : {
            lang: 'en',
            translations: {}
        };
    }
    async menus() {
        var _a;
        const { response } = await this.request('GET', "menus");
        return (_a = response.data) !== null && _a !== void 0 ? _a : [];
    }
}
export default createInstance(App);
