import AvatarImg from "@/components/AvatarImg.vue";
import BaseButtonDanger from "@/components/Base/BaseButtonDanger.vue";
import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
import BaseFormApp from "@/components/Base/BaseFormApp.vue";
import BaseFormGroup from "@/components/Base/BaseFormGroup.vue";
import BaseModal from "@/components/Base/BaseModal.vue";
import BaseTable from "@/components/Base/BaseTable.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import { CrewBoardSaveAct, CrewStatus } from "@/enums";
import crewHttp from "@/http/crew";
import crewBoardHttp from "@/http/crewBoard";
import { baseModal, helperMixin, saveAndUpdate } from "@/mixins";
import { defineComponent } from "vue";
export default defineComponent({
    components: {
        BaseModal,
        BaseFormGroup,
        BaseButtonDanger,
        BaseButtonPrimary,
        BaseTable,
        BaseTableRow,
        BaseTableColumn,
        AvatarImg,
        BaseFormApp,
    },
    mixins: [
        helperMixin,
        baseModal,
        saveAndUpdate(crewBoardHttp()),
    ],
    emits: ["done", "update:crewBoard", "update:modelValue"],
    props: {
        crewBoard: {
            type: Object,
            required: true,
        },
    },
    computed: {
        crewHttp,
        redirectAfterSubmit() {
            return false;
        },
    },
    data() {
        return {
            crew: null,
            crewSelectFilter: {
                status: CrewStatus.StandBy,
            },
            replacedCrewText: `${this.crewBoard.crew.crew_id} - ${this.crewBoard.crew.job_applicant.user.fullname}`,
        };
    },
    methods: {
        afterSubmit() {
            this.$emit("update:crewBoard", null);
            this.$emit("update:modelValue", false);
        },
        onCrewChange(crew) {
            this.crew = crew;
        },
        onModalHide() {
            var _a;
            (_a = this.$refs.switchCrewSelect) === null || _a === void 0 ? void 0 : _a.resetSelect();
            this.validationErrors = {};
        },
        setForm() {
            this.form.act = CrewBoardSaveAct.Replacement;
            this.form.old_crew_board_id = this.crewBoard.id;
            this.form.sign_on = this.crewBoard.sign_off;
            this.form.sign_off = "";
            this.form.crew_id = "";
            this.crew = null;
        },
    },
});
