import { defineComponent } from "vue";
import appStore from "@/store/app";
export default defineComponent({
    emits: ["click"],
    computed: {
        appStore,
    },
    props: {
        to: {
            type: Object
        }
    },
    methods: {
        onClick(e) {
            if (this.appStore.state.currKey)
                return;
            this.$emit("click", e);
            if (this.to) {
                // di set false di @/layout/App.vue
                this.appStore.state.loadingRoute = true;
            }
        }
    },
});
