/**
 * LocalStorage wrapper
 */
export default class LocalStorage {
    static getInstance() {
        if (!this.instance) {
            this.instance = new LocalStorage();
        }
        return this.instance;
    }
    set(key, value) {
        return localStorage.setItem(key, value);
    }
    get(key) {
        return localStorage.getItem(key);
    }
    remove(key) {
        return localStorage.removeItem(key);
    }
    addEventListener(key, callback) {
        window.addEventListener("storage", () => {
            callback(localStorage.getItem(key));
        });
    }
}
