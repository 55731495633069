import { randomString } from "@/helpers";
import { defineComponent } from "vue";
export default defineComponent({
    props: {
        multiple: {
            type: Boolean,
            default: false,
        },
        accept: {
            type: String,
        },
        toBase64: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["update:modelValue", "updated"],
    data() {
        return {
            inputValue: "",
            imageURL: "",
            file: null,
        };
    },
    computed: {
        randId() {
            return randomString();
        },
    },
    methods: {
        getImageURL(file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.imageURL = reader.result;
            };
        },
        base64encode(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    this.imageURL = reader.result;
                    resolve(reader.result.replace("data:", "").replace(/^.+,/, ""));
                };
                reader.onerror = reject;
            });
        },
        async onchange(e) {
            const files = e.target.files || e.dataTransfer.files;
            if (this.multiple) {
                this.inputValue = [];
                this.file = [];
                for (let i = 0; i < files.length; i++) {
                    this.file.push(files[0]);
                    await new Promise(async (resolve) => {
                        this.inputValue.push(this.toBase64 ? await this.base64encode(files[i]) : files[i]);
                        resolve;
                    });
                }
            }
            else {
                this.file = files[0];
                this.getImageURL(this.file);
                this.inputValue = this.toBase64
                    ? await this.base64encode(files[0])
                    : files[0];
            }
            this.$emit("update:modelValue", this.inputValue);
        },
    },
});
