import { defineComponent } from "vue";
export default defineComponent({
    props: {
        type: {
            type: String,
            default: "data"
        },
        textAlign: {
            type: String,
            required: false
        }
    }
});
