import { createInstance } from "@/lib/fn";
import BaseResourceHttp from "@/lib/resourceHttp";
class Notification extends BaseResourceHttp {
    constructor() {
        super(...arguments);
        this.ignoreMethods = ["create", "update", "delete"];
    }
    baseURL() {
        return "notification";
    }
    markAllAsRead() {
        return this.request("POST", this.url(`read_all`));
    }
    markAsRead(id) {
        return this.request("POST", this.url(`read/${id}`));
    }
    async paginate(filter) {
        var _a;
        const { response, status, message } = await this.request('GET', this.baseURL(), {
            params: filter
        });
        return {
            message,
            status,
            data: (_a = response.data) !== null && _a !== void 0 ? _a : [],
            has_more: response.has_more,
            total: response.total,
            unread: response.unread
        };
    }
}
export default createInstance(Notification);
