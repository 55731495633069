import { RouteNames, UserRole } from "@/enums";
import App from "@/layout/App.vue";
import Subscription from "@/layout/Subscription.vue";
const context = require.context("./auth", true, /\.ts$/i);
const requireAuthRoutes = [];
context.keys().forEach((file) => {
    const splitDir = file.split("/");
    let ctx = context(file).default || context(file);
    if (splitDir.length === 3) {
        if (splitDir[1] === "admin") {
            ctx = {
                ...ctx,
                meta: {
                    ...ctx.meta,
                    userRole: UserRole.Admin,
                },
            };
        }
        else if (splitDir[1] === "superadmin") {
            ctx = {
                ...ctx,
                meta: {
                    ...ctx.meta,
                    userRole: UserRole.SuperAdmin,
                },
            };
        }
    }
    requireAuthRoutes.push(ctx);
});
export default [
    {
        path: "/",
        component: App,
        meta: {
            isRequireAuth: true,
        },
        children: requireAuthRoutes,
    },
    {
        path: "/identifier",
        name: RouteNames.Identifier,
        component: () => import("@/pages/Admin/Identifier.vue"),
        meta: {
            pageTitle: "layout.identifier.page_title",
            isRequireAuth: true,
        },
    },
    {
        path: "/",
        component: Subscription,
        meta: {
            isRequireAuth: true,
            userRole: UserRole.Admin,
        },
        children: [
            {
                path: "/paywall",
                name: RouteNames.Paywall,
                component: () => import("@/pages/Admin/Paywall.vue"),
                meta: {
                    pageTitle: "layout.paywall.page_title",
                    isRequireAuth: true,
                },
            },
            {
                path: "payment/:id",
                name: RouteNames.Payment,
                component: () => import("@/pages/Admin/Payment/index.vue"),
                meta: {
                    pageTitle: "layout.payment.payment",
                },
            },
        ],
    },
    {
        path: "/welcome",
        name: RouteNames.Welcome,
        component: () => import("@/pages/Admin/Welcome.vue"),
        meta: {
            pageTitle: "layout.welcome",
            isRequireAuth: true,
        },
    },
    {
        path: "/verify_email",
        name: RouteNames.VerifyEmail,
        component: () => import("@/pages/VerifyEmail.vue"),
        meta: {
            isRequireAuth: true,
        },
    },
    {
        path: "/login",
        name: RouteNames.Login,
        component: () => import("@/pages/Guest/Login.vue"),
        meta: {
            isRequireAuth: false,
        },
    },
    {
        path: "/signup",
        name: RouteNames.Register,
        component: () => import("@/pages/Guest/Register.vue"),
        meta: {
            isRequireAuth: false,
        },
    },
    // password reset
    {
        // password reset token diencode menggunakan base64 encoder
        path: "/password_reset/:passwordResetToken?",
        name: RouteNames.PasswordReset,
        component: () => import("@/pages/Guest/PasswordReset.vue"),
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: {
            name: RouteNames.Login,
        },
    },
];
