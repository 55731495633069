import BaseLink from "@/components/Base/BaseLink.vue";
import { defineComponent } from "vue";
export default defineComponent({
    components: { BaseLink },
    props: {
        to: {
            type: Object,
            default: null,
        },
    },
});
