import { helperMixin, uwuLoaderDelay } from "@/mixins";
import { defineComponent } from "vue";
export default defineComponent({
    mixins: [helperMixin, uwuLoaderDelay],
    emits: ["submit"],
    props: {
        withSaveButtonEvent: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            offSaveButtonEvent: null,
        };
    },
    mounted() {
        if (this.withSaveButtonEvent) {
            this.offSaveButtonEvent = this.pubSub.on(this.EventNames.SaveButtonAppClick, this.submit);
        }
    },
    beforeUnmount() {
        this.offSaveButtonEvent ? this.offSaveButtonEvent() : "";
    },
    methods: {
        submit() {
            this.$emit("submit");
        },
    },
});
