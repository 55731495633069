import { LocalStorageKeys } from "@/enums";
import LocalStorage from "@/lib/customStorage";
import { createInstance } from "@/lib/fn";
import Store from "@/lib/store";
class Auth extends Store {
    states() {
        var _a;
        return {
            identifier: (_a = LocalStorage.getInstance().get(LocalStorageKeys.Identifier)) !== null && _a !== void 0 ? _a : "",
            token: LocalStorage.getInstance().get(LocalStorageKeys.Token),
            user: {},
        };
    }
    constructor() {
        super();
        this.subsToken = [];
        LocalStorage.getInstance().addEventListener(LocalStorageKeys.Token, (val) => {
            this.state.token = val;
        });
    }
    revokeToken() {
        LocalStorage.getInstance().remove(LocalStorageKeys.Token);
        LocalStorage.getInstance().remove(LocalStorageKeys.Identifier);
        this.state.identifier = "";
        this.state.token = null;
        this.state.user = {};
    }
    setIdentifier(val) {
        this.state.identifier = val;
        LocalStorage.getInstance().set(LocalStorageKeys.Identifier, val);
    }
    attempt(s) {
        if (!s.token && s.user) {
            throw new Error("unable to attempt auth");
        }
        this.state.token = s.token;
        this.state.user = s.user;
        LocalStorage.getInstance().set(LocalStorageKeys.Token, s.token);
    }
    getAuthrizationHeader() {
        var _a;
        return `Bearer ${(_a = this.state.token) !== null && _a !== void 0 ? _a : ""}`;
    }
    authRoleCheck(role) {
        var _a;
        return ((_a = this.state.user) === null || _a === void 0 ? void 0 : _a.role) === role;
    }
}
const instance = createInstance(Auth);
export const authStore = instance;
export default instance;
