import auth from "@/store/auth";
import axios from "axios";
export default class Http {
    constructor() {
        this.validationErrors = {};
        this.onTransformRequest = (data) => {
            var _a;
            for (let key in data) {
                this.appendFormData(key, (_a = data[key]) !== null && _a !== void 0 ? _a : "");
            }
            return this.formData;
        };
        this.http = axios.create({
            baseURL: process.env.NODE_ENV === "production"
                ? process.env.VUE_APP_BASE_API_URL_PROD
                : process.env.VUE_APP_BASE_API_URL_DEV,
            transformRequest: this.onTransformRequest,
        });
        this.formData = new FormData();
    }
    appendFormData(name, value) {
        // value is a object and not a file ?
        if (typeof value === "object" && !(value instanceof File)) {
            // cuz array is read as object
            // if is array and is empty
            if (Array.isArray(value) && !value.length) {
                value = "";
            }
            else {
                // recursion until value not an object
                for (let key in value) {
                    // recursion...
                    // ex:
                    // parentName[theKey1] recursion 1
                    // parentName[theKey1][theKey2] recursion 2
                    // value of theKey2 is not an object ? it will stop.
                    this.appendFormData(`${name}[${key}]`, value[key]);
                }
                return;
            }
        }
        if (typeof value === "boolean") {
            value = value ? 1 : 0;
        }
        if (value == undefined) {
            value = "";
        }
        this.formData.append(name, value);
    }
    clearFormData() {
        this.validationErrors = {};
        this.formData = new FormData();
    }
    request(method, url, option) {
        this.clearFormData();
        const data = { ...option === null || option === void 0 ? void 0 : option.data };
        if (data) {
            // add laravel spoof
            if (String(method).toLocaleLowerCase() === "put") {
                method = "post";
                data._method = "put";
            }
            else if (String(method).toLocaleLowerCase() === "delete") {
                method = "post";
                data._method = "delete";
            }
        }
        return new Promise(async (resolve, reject) => {
            this.http
                .request({
                ...option,
                method,
                url,
                params: option === null || option === void 0 ? void 0 : option.params,
                data,
                headers: {
                    Identifier: auth().state.identifier,
                    Authorization: auth().getAuthrizationHeader(),
                    ...option === null || option === void 0 ? void 0 : option.headers,
                },
            })
                .then(({ data, status, headers }) => {
                var _a;
                resolve({
                    status,
                    message: (_a = data.message) !== null && _a !== void 0 ? _a : "",
                    response: data,
                    headers,
                });
            })
                .catch((e) => {
                var _a, _b, _c, _d, _e, _f;
                if (e.response) {
                    if (e.response.status === 422) {
                        this.validationErrors = (_a = e.response.data) === null || _a === void 0 ? void 0 : _a.errors;
                    }
                }
                else {
                    reject(e.toJSON());
                }
                resolve({
                    status: (_b = e.response) === null || _b === void 0 ? void 0 : _b.status,
                    message: (_d = (_c = e.response) === null || _c === void 0 ? void 0 : _c.data.message) !== null && _d !== void 0 ? _d : "",
                    response: (_e = e.response) === null || _e === void 0 ? void 0 : _e.data,
                    headers: (_f = e.response) === null || _f === void 0 ? void 0 : _f.headers,
                    validationErrors: this.validationErrors,
                });
            });
        });
    }
}
