import { RouteNames } from "@/enums";
import { createBaseChildrenRecordRaw } from "@/lib/fn";
export default createBaseChildrenRecordRaw("subscription_plan", {
    index: {
        name: RouteNames.SubscriptionPlan,
        component: () => import("@/pages/Superadmin/SubscriptionPlan/Index.vue"),
    },
    create: {
        name: RouteNames.SubscriptionPlanCreate,
        component: () => import("@/pages/Superadmin/SubscriptionPlan/Save.vue"),
    },
    edit: {
        name: RouteNames.SubscriptionPlanEdit,
        component: () => import("@/pages/Superadmin/SubscriptionPlan/Save.vue"),
    },
});
