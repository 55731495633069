import { createInstance } from "@/lib/fn";
import BaseResourceHttp from "@/lib/resourceHttp";
class JobApplicant extends BaseResourceHttp {
    constructor() {
        super(...arguments);
        this.ignoreMethods = ["create", "update", "delete"];
    }
    baseURL() {
        return "job_applicant";
    }
    process(candidate_ids) {
        return this.request("POST", this.url("process"), {
            data: {
                candidate_ids
            }
        });
    }
    rejects(candidate_ids) {
        return this.request("POST", this.url("rejects"), {
            data: {
                candidate_ids
            }
        });
    }
    acceptOrReject(id, act) {
        return this.request("POST", this.url(`accept_or_reject/${id}`), {
            data: {
                status: act
            }
        });
    }
    rescheduleResponse(id, act) {
        return this.request("POST", this.url(`${act}/${id}`));
    }
    createSchedules(candidates) {
        return this.request("POST", this.url('create_schedules'), {
            data: {
                candidates
            }
        });
    }
    async paginate(filter) {
        var _a;
        return super.paginate({
            ...filter,
            status_in: (_a = filter === null || filter === void 0 ? void 0 : filter.status_in) === null || _a === void 0 ? void 0 : _a.join(",")
        });
    }
}
export default createInstance(JobApplicant);
