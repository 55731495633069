import { defineComponent } from "vue";
import authStore from "@/store/auth";
import { Default } from "@/enums";
export default defineComponent({
    computed: {
        companyLogo() {
            var _a;
            return ((_a = authStore().state.user.company) === null || _a === void 0 ? void 0 : _a.logo_link) || Default.image;
        }
    }
});
