import { createInstance } from "@/lib/fn";
import BaseResourceHttp from "@/lib/resourceHttp";
class Selection extends BaseResourceHttp {
    baseURL() {
        return "selection";
    }
    async jobPositionByCompanyId(companyId) {
        var _a;
        const { response } = await this.request("GET", `${this.baseURL()}/job_position/${companyId}`);
        return (_a = response.data) !== null && _a !== void 0 ? _a : [];
    }
    async all(type) {
        var _a;
        const { response } = await this.request("GET", `${this.baseURL()}/all`, {
            params: {
                type,
            },
        });
        return (_a = response.data) !== null && _a !== void 0 ? _a : [];
    }
}
export const selectionHttp = createInstance(Selection);
export default selectionHttp;
