import { defineComponent } from "vue";
export default defineComponent({
    emits: ["menu-scroll"],
    props: {
        title: {
            type: String,
            required: false,
        },
    },
});
