import { createInstance } from "@/lib/fn";
import Http from "@/lib/http";
export class Auth extends Http {
    identifier(data) {
        return this.request("post", "auth/validate_company_identifier", {
            data,
        });
    }
    subscription() {
        return this.request("get", "auth/subscription");
    }
    updateCompany(data) {
        return this.request("PUT", "auth/company", {
            data,
        });
    }
    updateUser(data) {
        return this.request("PUT", "auth/user", {
            data,
        });
    }
    getUser() {
        return this.request("get", "auth/user");
    }
    resendEmailVerification() {
        return this.request("POST", "email/resend");
    }
    // user menginput pasword baru
    resetPassword(data) {
        return this.request("POST", "reset_password", {
            data,
        });
    }
    // saat udah dapet token, user klik linknya, divalidasi oleh sistem
    validatePasswordResetToken(token, email) {
        return this.request("POST", `validate_password_reset_token/${token}/${email}`);
    }
    // user meminta link reset password
    sendPasswordResetLink(email) {
        return this.request("POST", "send_password_reset_link", {
            data: {
                email,
            },
        });
    }
    async login(data) {
        const { headers, response, status, message, validationErrors } = await this.request("POST", "login", { data: data });
        return {
            headers,
            message,
            status: status,
            data: response.data,
            validationErrors,
        };
    }
    async register(data) {
        data.role = "admin";
        const { headers, response, status, message, validationErrors } = await this.request("POST", "register", { data: data });
        return {
            headers,
            message,
            status: status,
            data: response.data,
            validationErrors,
        };
    }
    logout() {
        return this.request("POST", "logout");
    }
}
const instance = createInstance(Auth);
export const authHttp = instance;
export default instance;
