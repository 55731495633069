import { RouterView } from "vue-router";
/**
 * put the function in your vue component props
 * better in computed
 *
 */
export function createInstance(theClass) {
    let instance = null;
    return () => {
        if (!instance) {
            instance = new theClass();
        }
        return instance;
    };
}
export function createChildrenRecordRaw(route) {
    return route;
}
/**
 * base for create CRUD page.
 * the path will create:
 *
 * basePath/,
 * basePath/create,
 * basePath/edit/:id,
 * basePath/detail/:id,
 */
export function createBaseChildrenRecordRaw(basePath, opt) {
    var _a, _b;
    const children = [
        ...((_a = opt.children) !== null && _a !== void 0 ? _a : []),
        {
            path: "",
            name: opt.index.name,
            component: opt.index.component,
            meta: {
                withContentTools: true,
                ...opt.index.meta,
            },
        },
    ];
    const additionalRoutes = [
        {
            key: "create",
            path: "create",
            extraMeta: { withBackButton: true, withContentFooter: true },
        },
        {
            key: "edit",
            path: "edit/:id",
            extraMeta: { withBackButton: true, withContentFooter: true },
        },
        { key: "detail", path: ":id", extraMeta: { withBackButton: true } },
    ];
    for (const { key, path, extraMeta } of additionalRoutes) {
        const routeOpt = opt[key];
        if (routeOpt) {
            children.push({
                path,
                name: routeOpt.name,
                component: routeOpt.component,
                meta: {
                    breadcrumbs: [key],
                    ...extraMeta,
                    ...routeOpt.meta,
                },
            });
        }
    }
    return createChildrenRecordRaw({
        path: basePath,
        component: RouterView,
        meta: {
            rootName: opt.index.name,
            createName: (_b = opt.create) === null || _b === void 0 ? void 0 : _b.name,
        },
        children,
    });
}
