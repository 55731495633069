import LoadingPage from "@/components/LoadingPage.vue";
import { CompanySubscriptionStatusEnum, RouteNames, UserRole } from "@/enums";
import authFacade from "@/facades/auth";
import { useRouter } from "@/lib/router";
import { helperMixin } from "@/mixins";
import auth, { authStore } from "@/store/auth";
import { defineComponent } from "vue";
import { authHttp } from "@/http/auth";
import { currentSubscription } from "@/helpers";
import { appStore } from "@/store/app";
export default defineComponent({
    mixins: [helperMixin],
    components: {
        LoadingPage,
    },
    data() {
        return {
            isError: false,
        };
    },
    async mounted() {
        try {
            await this.appStore.fetchConfig();
            document.documentElement.lang = this.appStore.state.lang;
            await this.$nextTick();
        }
        catch (e) {
            this.isError = true;
        }
    },
    setup() {
        // const loading = ref(true);
        let passRouteCheck = false;
        const validateSubscription = async (navigate) => {
            if (authStore().state.user.role === UserRole.SuperAdmin) {
                return true;
            }
            const { status } = await authHttp().subscription();
            if (status === 403) {
                passRouteCheck = true;
                if (currentSubscription().status ===
                    CompanySubscriptionStatusEnum.NeedApproval) {
                    navigate({
                        name: RouteNames.Welcome,
                    });
                }
                else if (currentSubscription().status ===
                    CompanySubscriptionStatusEnum.NonActive ||
                    currentSubscription().status_tmp ===
                        CompanySubscriptionStatusEnum.NonActive) {
                    navigate({
                        name: RouteNames.Paywall,
                    });
                }
                else if (!authStore().state.identifier) {
                    navigate({
                        name: RouteNames.Identifier,
                    });
                }
                return false;
            }
            return true;
        };
        auth().watch("token", async (val) => {
            // loading.value = true;
            appStore().state.loadingPage = true;
            if (!val) {
                passRouteCheck = true;
                await useRouter().push({
                    name: RouteNames.Login,
                });
            }
            else {
                const { failed, status } = await authFacade().validateToken();
                if (!failed) {
                    if (await validateSubscription(useRouter().push)) {
                        passRouteCheck = true;
                        await useRouter().push({
                            name: RouteNames.Dashboard,
                        });
                    }
                }
                else if (status === 401) {
                    passRouteCheck = true;
                    await useRouter().push({
                        name: RouteNames.Login,
                    });
                }
                else if (status === 403) {
                    passRouteCheck = true;
                    await useRouter().push({
                        name: RouteNames.VerifyEmail,
                    });
                }
            }
            appStore().state.loadingPage = false;
            // loading.value = false;
        });
        useRouter().beforeEach(async (to, from, next) => {
            var _a, _b;
            if (passRouteCheck) {
                return next();
            }
            else {
                // request into guest page like 'login' and 'register'
                // and current token is not null
                if (!((_a = to.meta) === null || _a === void 0 ? void 0 : _a.isRequireAuth) && auth().state.token) {
                    // validate token in App/Main route
                    passRouteCheck = false;
                    return next({
                        name: RouteNames.Dashboard,
                    });
                }
                // into auth page
                // and validate token
                else if ((_b = to.meta) === null || _b === void 0 ? void 0 : _b.isRequireAuth) {
                    const { failed, status, data } = await authFacade().validateToken();
                    if (failed) {
                        if (status === 401 || status === 0) {
                            auth().revokeToken();
                            passRouteCheck = true;
                            return next({
                                name: RouteNames.Login,
                            });
                        }
                        else if (status === 403) {
                            return next({
                                name: RouteNames.VerifyEmail,
                            });
                        }
                    }
                    if (!(await validateSubscription(next))) {
                        return;
                    }
                    if (to.meta.userRole) {
                        if (auth().state.user.role !== to.meta.userRole) {
                            return next({
                                name: RouteNames.Dashboard,
                            });
                        }
                    }
                }
                passRouteCheck = true;
                next(to);
            }
        });
    },
    watch: {
        currentRoute() {
            var _a, _b, _c;
            this.setPageTitle(this.trans((_c = (_b = (_a = this.currentRoute) === null || _a === void 0 ? void 0 : _a.meta) === null || _b === void 0 ? void 0 : _b.pageTitle) !== null && _c !== void 0 ? _c : ""));
        },
    },
});
