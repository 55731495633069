import routes from '@/routes';
import { createRouter, createWebHistory, } from 'vue-router';
const router = createRouter({
    history: createWebHistory(),
    routes
});
export function useRouter() {
    return router;
}
export default router;
