import { createInstance } from "@/lib/fn";
import Store from "@/lib/store";
class ContentTool extends Store {
    states() {
        return {
            search: "",
            filterCount: 0,
        };
    }
}
export default createInstance(ContentTool);
