import authHttp from "@/http/auth";
import { createInstance } from "@/lib/fn";
import auth from "@/store/auth";
export var AttemptType;
(function (AttemptType) {
    AttemptType[AttemptType["Login"] = 0] = "Login";
    AttemptType[AttemptType["Register"] = 1] = "Register";
})(AttemptType || (AttemptType = {}));
class AuthFacade {
    /**
     * Get access token from spesific request
     */
    async attempt(type, formData) {
        let response = {};
        if (type === AttemptType.Login) {
            response = await authHttp().login(formData);
        }
        else if (type === AttemptType.Register) {
            response = await authHttp().register(formData);
        }
        if (response.status === 200 && response.data.token && response.data.user) {
            try {
                auth().attempt(response.data);
                return Promise.resolve({
                    failed: false,
                    message: response.message,
                    validationErrors: response.validationErrors,
                });
            }
            catch (e) {
                return Promise.resolve({
                    failed: true,
                    message: e,
                    validationErrors: response.validationErrors,
                });
            }
        }
        return Promise.resolve({
            failed: true,
            message: response.message,
            validationErrors: response.validationErrors,
        });
    }
    async validateToken() {
        if (!auth().state.token) {
            return Promise.resolve({
                failed: true,
                status: 0,
            });
        }
        const { status, response } = await authHttp().getUser();
        if (status !== 200) {
            return Promise.resolve({
                failed: true,
                status: status,
                data: response.data,
            });
        }
        auth().state.user = response.data;
        return Promise.resolve({
            failed: false,
            status,
        });
    }
}
const o = createInstance(AuthFacade);
export const authFacade = o;
export default o;
